/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 20/06/2022.
 */
import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import { PROJECT_DISPLAY_TITLE } from '../constant'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'

type Props = { location: any }

const EmailLogin = ({ location }: Props) => {
  const userEmail = location.state?.userEmail || 'Email missing'
  const { t } = useTranslation()

  const mailToLink =
    'mailto:support@studiesandme.com?subject=Question about the ' + PROJECT_DISPLAY_TITLE.toUpperCase() + ' study'

  return (
    <Layout location={location} title={'Email confirmation'}>
      <div className="container mt-6 d-flex align-items-center" style={{ minHeight: '50vh' }}>
        <div className="row" style={{}}>
          <div className="col-md-8 col-12 d-flex align-items-center ">
            <div className="px-md-5 px-2 py-5 border h-100 d-flex flex-column justify-content-center">
              <div className="">
                {/* Welcome back */}
                <h3 className="">{t('headline')}</h3>
                {/* Thank you for returning to the study website */}
                <h5 className="mt-3 pe-4">{t('body1')}</h5>
                {/* It looks like you have already signed up with this email address */}
                <h5 className="mt-3 pe-4">{t('body2')}</h5>
                {/* In order to return to your personal version of the study website, please use the login link which was sent to your email: */}
                <p className="mt-3 pe-4">
                  {t('paragraph1')}
                  {userEmail}
                </p>

                {/*<h6 className="fw-bold">A link was sent to: {userEmail} </h6>*/}
                {/* Click the link provided in the email to continue your application for the study. */}
                <p className="mt-3">{t('paragraph2')}</p>
                <p>
                  {/*                To access the {NEW_STUDY_NAME} please use the login link that has been sent to the following email
                address:*/}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-12 mt-4 mt-md-0  d-flex justify-content-center align-items-center">
            <div className="py-5 px-5 border">
              {/* Our support team is always ready to help */}
              <h6 className="fw-bold text-center px-4 my-4"> {t('supportHeadline')}</h6>
              <div className="d-flex my-3 justify-content-center">
                <StaticImage
                  placeholder={'none'}
                  quality={100}
                  loading="lazy"
                  src={'../images/support.png'}
                  alt="suppport"
                ></StaticImage>
              </div>
              {/* Please send your your inquiry via email. Our study personnel will get back to you within three working
                days. */}
              <p className="text-center mb-4 mt-2"> {t('supportBody')}</p>
              <a href={mailToLink}>
                <p className="fw-bold text-center">{t('supportEmail')}</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default EmailLogin

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
